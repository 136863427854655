<template>
  <div id="centreRight1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color:#5cd9e8">
          <icon name="chart-line"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">当日工资统计</span>
        </div>
      </div>
      <div class="d-flex jc-center body-box"  style="height:90%; font-size: 0.5rem;color:#ffc103" >
        <dv-scroll-board :config="config"  />
      </div>
    </div>
  </div>
</template>

<script>
import {getWageListBigapi} from '../../libs/axios';
export default {
  data() {
    return {
      config: {
        rowNum: 4, //表格行数
        header: ["姓名","金额"],
        data:[],
        headerHeight: 65,
        headerBGC: "#0f1325", //表头
        oddRowBGC: "#0f1325", //奇数行
        evenRowBGC: "#171c33", //偶数行
        index: true, 
        columnWidth: [130,380,380],
        align: ["center"]
      }
    };
  },
  components: {},
  mounted() {
    this.init();
     setInterval(this.init,120000);
  },
  methods: {
    init() {
      this.getWageList();
    },
     getWageList() {
       
      this.loading = true;
      var workid=this.$store.state.workid;
      var seqWageGuids=this.$store.state.seqWageGuids;
      var deptId=this.$store.state.deptId;
      getWageListBigapi({deptId:deptId,workid:workid,seqGuids:seqWageGuids}).then((res) => {
          this.loading = false;
          this.$set(this.config,  this.config.data,res.data);
          this.config.data=res.data;
      });
    },
  }
};
</script>

<style lang="scss">
#centreRight1 {
  padding: 0.2rem;
  height: 100%;
  min-width: 3.75rem;
  border-radius: 0.0625rem;
  font-size: 0.7rem;
  .bg-color-black {
    height: 4.5rem;
    border-radius: 0.125rem;
    font-size:0.7rem;
  }
  .text {
    font-size: 0.5rem;
    color: #c3cbde;
  }
  .body-box {
    border-radius: 0.125rem;
    overflow: hidden;
    font-size: 0.7rem;
  }
  .header-item{
    font-size: 0.8rem;
  }
    .row-item{
    font-size: 0.7rem;
    color:"#ffc103"
  }
  .ceil{
    color: rgb(255, 193, 3);
  }
}
</style>