<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue'
import {getDay7SeqNameCountBig} from '../../../../libs/axios';
export default {
  data () {
    return {
      cdata: "",
    };
  },
  components: {
    Chart,
  },
  mounted () {
    this.setData();
     setInterval(this.setData,120000);
  },
  methods: {
    // 根据自己的业务情况修改
    setData () {
      this.loading = true;
      var workid=this.$store.state.workid;
      var seqGuids=this.$store.state.seqGuids;
       var seqNames=this.$store.state.seqNames;
        var deptId=this.$store.state.deptId;
      getDay7SeqNameCountBig(
        {
          deptId:deptId,
          workid:workid,
          seqGuids:seqGuids,
          seqNames:seqNames
        }
      ).then((res) => {
        this.loading = false;
          this.cdata=res.data;
      });
    },
  }
};
</script>

<style lang="scss" scoped>
</style>